$(function() {
  $('.btn-toggle-password').on('click', function() {
    const passwordInputGroup = $(this).closest('.input-group');
    const passwordInput = passwordInputGroup.find('.password-toggle-input');

    const type = passwordInput.attr('type') === 'password' ? 'text' : 'password';
    passwordInput.attr('type', type);

    $(this).html(type === 'password' ? '<i class="fas fa-eye"></i>' : '<i class="fas fa-eye-slash"></i>');
  });
});